<template>
    <b-modal id="deleteDeviceModal" centered header-bg-variant="danger" @ok="deleteDevice">

        <div slot="modal-header" class="w-100">
            <h2 class="text-center">{{ $t('devices.deleteDeviceTitle') }}</h2>
        </div>

        <b-form-group>
            {{ $t('devices.deleteDeviceDesc') }}
        </b-form-group>

        <div slot="modal-ok">{{ $t('general.confirm') }}</div>
        <div slot="modal-cancel">{{ $t('general.cancel') }}</div>
    </b-modal>
</template>

<script>
    export default {
        name: "DeleteDeviceModal",

        data() {
            return {
                deviceToDeleteId: ""
            }
        },

        mounted() {
            this.$root.$on('device-to-delete-id', this.setDeviceToDeleteId);
        },

        destroyed() {
            this.$root.$off('device-to-delete-id');
        },

        methods: {
            setDeviceToDeleteId(deviceToDeleteId) {
                this.deviceToDeleteId = deviceToDeleteId;
            },

            deleteDevice() {
                this.$axios({
                    method: 'delete',
                    url: '/delete-device',
                    data: {
                        'id': this.deviceToDeleteId
                    },
                    timeout: 10000
                }).then(() => {
                    this.$root.$emit('showInfo', "Your device was deleted successfully");
                    this.$root.$emit('bv::show::modal', 'info-modal');
                    this.$root.$emit('deleted-device', this.deviceToDeleteId);
                }).catch((err) => {
                    let error = err.response.data;

                    if (error.type && error.type === "DemoAccountError") {
                        this.$root.$emit('showError', error.body);
                    } else {
                        this.$root.$emit('showError', 'An error occurred.');
                    }

                    this.$root.$emit('bv::show::modal', 'error-modal');
                });
            },
        }
    }
</script>
