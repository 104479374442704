<template>
    <b-modal id="linkingOptionsModal" :title="$t('devices.linkingMethod')" centered header-bg-variant="primary">
        <b-row>
            <b-col>
                <b-img @click="onQRCodeImageClick" src="img/linking-options-qr-code.png" thumbnail fluid width="150" alt="QR code" right></b-img>
            </b-col>

            <b-col>
                <b-img @click="onPasscodeImageClick" src="img/linking-options-passcode.png" thumbnail fluid width="150" alt="Passcode" left></b-img>
            </b-col>
        </b-row>
    </b-modal>
</template>

<script>
    export default {
        name: "LinkingOptionsModal",

        data() {
            return {
                deviceToLinkId: ""
            }
        },

        mounted() {
            this.$root.$on('device-to-link-id', this.setDeviceToLinkId);
        },

        destroyed() {
            this.$root.$off('device-to-link-id');
        },

        methods: {
            onQRCodeImageClick() {
                this.$bvModal.hide('linkingOptionsModal');

                window.cordova.plugins.barcodeScanner.scan(
                    result => {
                        if (!result.cancelled) {
                            this.$root.$emit('send-link-request', this.deviceToLinkId, result.text);
                        }
                    },
                    err => {
                        console.error(err);
                        //could not access camera so try the old way
                        this.$root.$emit('bv::show::modal', 'LinkByPasscodeModal');
                    },
                    {
                        showTorchButton: false,
                        disableSuccessBeep: true,
                        prompt: this.$t('devices.scanQRCode'),
                        formats: "QR_CODE",
                        resultDisplayDuration: 0
                    }
                );
            },

            onPasscodeImageClick() {
                this.$bvModal.hide('linkingOptionsModal');
                this.$root.$emit('bv::show::modal', 'LinkByPasscodeModal');
            },

            setDeviceToLinkId(deviceToLinkId) {
                this.deviceToLinkId = deviceToLinkId;
            }
        }
    }
</script>

<style scoped>

</style>