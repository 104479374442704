<template>
    <div class="mt-lg-3 mt-1 pt-3 pb-0 px-3">
        <VirtualDeviceList/>

        <InfoModal/>

        <ErrorModal/>
    </div>
</template>

<script>
    import store from '@/store';
    import VirtualDeviceList from "@/components/virtual-device/VirtualDeviceList";
    import FloatingActionButton from "@/components/layout/FloatingActionButton";
    import ErrorModal from "@/components/general-purpose-modals/ErrorModal";
    import InfoModal from "@/components/general-purpose-modals/InfoModal";
    import {isMobileNavigationMode} from "@/utilities/mobile.navigation.utility";

    export default {
        name: 'dashboard',

        components: {
            InfoModal,
            ErrorModal,
            FloatingActionButton,
            VirtualDeviceList
        },

        async beforeRouteEnter(to, from, next) {
            await store.dispatch('user/fetchUserSettings');
            let response = await store.dispatch('virtualDevice/fetchDeviceList', to.params.id);

            next(vm => {
                vm.$store.commit('virtualDevice/emptyDeviceList');

                let deviceList = response.data;
                for (let i = 0; i < deviceList.length; i++) {
                    vm.$store.commit('virtualDevice/addDevice', deviceList[i]);
                }
            });
        },

        mounted() {
            //In case user opens the mobile app while there is no internet connection
            if ((this.$platform !== 'web' && navigator.onLine === false)) {
                window.addEventListener('online', this.refreshDeviceList);
            }
        },

        computed: {
            showingBottomNav: function () {
                return isMobileNavigationMode(this.$platform);
            }
        },

        methods: {
            async refreshDeviceList() {
                await store.dispatch('virtualDevice/fetchDeviceList', this.$route.params.id);

                window.removeEventListener('online', this.refreshDeviceList);
            }
        }
    }
</script>

<style scoped>
</style>
