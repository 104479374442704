<template>
    <b-modal id="unlinkDeviceModal" centered header-bg-variant="warning" @ok="unlinkDevice">
        <div slot="modal-header" class="w-100">
            <h2 class="text-center">{{ $t('devices.unlinkDeviceTitle') }}</h2>
        </div>

        <p>{{ $t('devices.unlinkDeviceDesc') }}</p>

        <div slot="modal-ok">{{ $t('general.confirm') }}</div>
        <div slot="modal-cancel">{{ $t('general.cancel') }}</div>
    </b-modal>
</template>

<script>
    export default {
        name: "Modal",

        data() {
            return {
                deviceToUnlinkId: ""
            }
        },

        mounted() {
            this.$root.$on('device-to-unlink-id', this.setDeviceToUnlinkId);
        },

        destroyed() {
            this.$root.$off('device-to-unlink-id');
        },

        methods: {
            setDeviceToUnlinkId(deviceToUnlinkId) {
                this.deviceToUnlinkId = deviceToUnlinkId;
            },

            unlinkDevice() {
                this.$axios({
                    method: 'put',
                    url: '/unlink-device',
                    params: {
                        'id': this.deviceToUnlinkId,
                    },
                    timeout: 10000
                }).then(response => {
                    this.$root.$emit('showInfo', "Your device was unlinked successfully");
                    this.$root.$emit('bv::show::modal', 'info-modal');
                    this.$root.$emit('unlinked-device', response.data.id);
                }).catch((err) => {
                    let error = err.response.data;
                    if (error.type) {
                        if (error && error.type === "ValidationError") {
                            this.$root.$emit('showError', this.$format(error.body.name));
                        } else if (error.type === "DemoAccountError") {
                            this.$root.$emit('showError', error.body);
                        }
                    } else {
                        this.$root.$emit('showError', 'Cannot unlink device.');
                    }

                    this.$root.$emit('bv::show::modal', 'error-modal');
                }).finally(() => {
                });
            },
        }

    }
</script>
