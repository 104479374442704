<template>
    <b-col sm="6" lg="3">
        <b-card class="bg-primary" style="border: none; border-radius: 8px; box-shadow: 0px 10px 20px rgba(0, 0, 0, .18) !important;"
                body-class="cardBody">
            <div class="mt-3 mb-0 mx-3">
                <h4>{{ device.name }}</h4>
                <p>{{ deviceStatus }}</p>
            </div>

            <div style="height: 4.8rem;"/>

            <div class="mb-1 mx-1">
                <b-btn class="w-25 device-button" variant="primary" @click="viewVirtualDevice">
                    <i class="icon-magnifier"/>
                </b-btn>

                <b-btn class="w-25 device-button" variant="primary" @click="editVirtualDevice">
                    <i class="icon-wrench"/>
                </b-btn>

                <b-btn v-if="!device.mac_address && device.privilege_level === 3"
                       class="w-25 device-button" variant="primary" @click="linkVirtualDevice">
                    <i class="icon-globe"/>
                </b-btn>

                <b-btn v-if="device.mac_address && device.privilege_level === 3"
                       class="w-25 device-button" variant="primary" @click="unlinkVirtualDevice">
                    <i class="icon-logout"/>
                </b-btn>

                <b-btn v-if="device.privilege_level === 3"
                       class="w-25 device-button" variant="primary" @click="deleteVirtualDevice">
                    <i class="icon-trash"/>
                </b-btn>
            </div>
        </b-card>
    </b-col>
</template>

<script>
    import {computed} from "@vue/composition-api";

    export default {
        name: "VirtualDeviceItem",

        props: [
            'device'
        ],

        setup(props, {root, emit}) {
            const deviceStatus = computed(() => {
                let macAddress = props.device.mac_address

                if (macAddress) return macAddress;
                else return props.device.unlinked ? root.$t('devices.unlinked') : root.$t('devices.empty');
            });

            function viewVirtualDevice() {
                emit('view', props.device.id);
            }

            function editVirtualDevice() {
                emit('edit', props.device.id);
            }

            function deleteVirtualDevice() {
                emit('delete', props.device.id);
            }

            function linkVirtualDevice() {
                emit('link', props.device.id);
            }

            function unlinkVirtualDevice() {
                emit('unlink', props.device.id);
            }

            return {
                deviceStatus,
                viewVirtualDevice,
                editVirtualDevice,
                deleteVirtualDevice,
                linkVirtualDevice,
                unlinkVirtualDevice
            }
        }
    }
</script>

<style scoped>
    .cardBody {
        padding: 0;
    }

    .device-button:focus {
        box-shadow: none;
    }
</style>
