<template>
    <b-modal ref="LinkByPasscode" id="LinkByPasscodeModal" centered header-bg-variant="primary" @ok="linkDevice">

        <div slot="modal-header" class="w-100">
            <h2 class="text-center">{{ $t('devices.linkDeviceTitle') }}</h2>
        </div>

        <b-form-group id="fieldset1" :label="$t('devices.linkDeviceDesc')" label-for="passcode-input" :invalid-feedback="invalidFeedback"
                      :valid-feedback="validFeedback" f :state="state">
            <b-form-input id="passcode-input" type="number" :state="state" v-model.trim="passcode"
                          @keypress.enter.native="pressedEnter" @click="$root.$emit('hideBottomNav')"/>
        </b-form-group>

        <div slot="modal-ok">{{ $t('general.confirm') }}</div>
        <div slot="modal-cancel">{{ $t('general.cancel') }}</div>
    </b-modal>
</template>

<script>
    export default {
        name: "LinkByPasscodeModal",

        computed: {
            state() {
                return this.passcode.length == 6 ? true : false
            },
            invalidFeedback() {
                if (this.passcode.length > 0) {
                    return this.$t('devices.passcode6Digits');
                } else {
                    return this.$t('devices.passcodeNotEmpty');
                }
            },
            validFeedback() {
                return this.state === true ? this.$t('devices.ready') : ''
            }
        },

        data() {
            return {
                deviceToLinkId: "",
                passcode: ""
            }
        },

        mounted() {
            this.$root.$on('device-to-link-id', this.setDeviceToLinkId);
        },

        destroyed() {
            this.$root.$off('device-to-link-id');
        },

        methods: {
            pressedEnter() {
                this.$bvModal.hide('LinkByPasscodeModal')
                this.linkDevice();
            },

            setDeviceToLinkId(deviceToLinkId) {
                this.passcode = "";
                this.deviceToLinkId = deviceToLinkId;
            },

            linkDevice() {
                this.$root.$emit('send-link-request', this.deviceToLinkId, this.passcode);
            }
        }

    }
</script>
