5<template>
    <b-card class="card-material" style="overflow-x: auto;" no-body>
        <b-table class="mb-0" style="min-width: 35rem;" :fields="fields" :items="tableViewDevices" borderless striped hover>
            <template v-slot:cell(actions)="row">
                <b-button size="sm" @click="viewVirtualDevice(row.item.id)" class="action-button mr-2">
                    <i class="icon-magnifier"/>
                </b-button>

                <b-button size="sm" @click="editVirtualDevice(row.item.id)" class="action-button mr-2">
                    <i class="icon-wrench"/>
                </b-button>

                <b-button v-if="!row.item.mac_address && row.item.privilege_level === 3" size="sm" @click="linkVirtualDevice(row.item.id)"
                          class="action-button mr-2">
                    <i class="icon-globe"/>
                </b-button>

                <b-button v-if="row.item.mac_address && row.item.privilege_level === 3" size="sm" @click="unlinkVirtualDevice(row.item.id)"
                          class="action-button mr-2">
                    <i class="icon-logout"/>
                </b-button>

                <b-button v-if="row.item.privilege_level === 3" size="sm" @click="deleteVirtualDevice(row.item.id)" class="action-button mr-2">
                    <i class="icon-trash"/>
                </b-button>
            </template>
        </b-table>
    </b-card>
</template>

<script>
    import {computed} from "@vue/composition-api";

    export default {
        name: "VirtualDeviceTableView",

        props: ['deviceList'],

        setup(props, {root, emit}) {
            const tableViewDevices = computed(() => {
                let arr = [];

                for (let device of props.deviceList) {
                    arr.push({
                        id: device.id,
                        mac_address: device.mac_address,
                        privilege_level: device.privilege_level,
                        name: device.name,
                        desc: device.desc,
                        status: computed(() => getDeviceStatus(device)).value
                    });
                }

                return arr;
            })

            let fields;
            if (root.$store.getters['user/isAdmin']) {
                fields = [
                    {key: 'desc', label: root.$t('devices.desc')},
                    {key: 'actions', label: root.$t('devices.actions')},
                    {key: 'status', label: root.$t('devices.status')},
                    {key: 'name', label: root.$t('devices.name')},
                ];
            } else {
                fields = [
                    {key: 'name', label: root.$t('devices.name')},
                    {key: 'status', label: root.$t('devices.status')},
                    {key: 'actions', label: root.$t('devices.actions')}
                ];
            }

            function getDeviceStatus(device) {
                let macAddress = device.mac_address

                if (macAddress) return macAddress;
                else return device.unlinked ? root.$t('devices.unlinked') : root.$t('devices.empty');
            }

            function viewVirtualDevice(deviceId) {
                emit('view', deviceId);
            }

            function editVirtualDevice(deviceId) {
                emit('edit', deviceId);
            }

            function deleteVirtualDevice(deviceId) {
                emit('delete', deviceId);
            }

            function linkVirtualDevice(deviceId) {
                emit('link', deviceId);
            }

            function unlinkVirtualDevice(deviceId) {
                emit('unlink', deviceId);
            }

            return {
                tableViewDevices,
                fields,
                viewVirtualDevice,
                editVirtualDevice,
                deleteVirtualDevice,
                linkVirtualDevice,
                unlinkVirtualDevice
            }
        }
    }
</script>

<style scoped>
    .action-button {
        background: white;
        border-color: var(--primary) !important;
        color: var(--primary) !important;
        outline: none !important;
        box-shadow: none !important;
    }

    .action-button:active {
        background: lightblue !important;
    }
</style>