<template>
    <b-modal ref="myModal" id="addDeviceModal" centered header-bg-variant="primary" @ok="addDevice" :ok-disabled="!state">

        <div slot="modal-header" class="w-100">
            <h2 class="text-center">{{ $t('devices.addDeviceTitle') }}</h2>
        </div>

        <b-form-group id="fieldset1" :label="$t('devices.addDeviceDesc')" label-for="name-input" :invalid-feedback="invalidFeedback"
                      :valid-feedback="validFeedback" f :state="state">
            <b-form-input id="name-input" :state="state" v-model.trim="name" @keypress.enter.native="pressedEnter"
                          @click="$root.$emit('hideBottomNav')"/>
        </b-form-group>

        <div slot="modal-ok">{{ $t('general.confirm') }}</div>
        <div slot="modal-cancel">{{ $t('general.cancel') }}</div>
    </b-modal>
</template>

<script>
    export default {
        name: "Modal",

        computed: {
            state() {
                return this.name.length >= 4 && this.name.length <= 20
            },

            invalidFeedback() {
                if (this.name.length > 4 && this.name.length <= 20) {
                    return ''
                } else if (this.name.length > 20) {
                    return this.$t('devices.nameMoreThan20Chars');
                } else if (this.name.length > 0) {
                    return this.$t('devices.nameMoreThan4Chars');
                } else {
                    return this.$t('devices.nameEmpty');
                }
            },

            validFeedback() {
                return this.state === true ? this.$t('devices.ready') : '';
            }
        },

        data() {
            return {
                name: ""
            }
        },

        methods: {
            pressedEnter() {
                if (this.state) {
                    this.$refs.myModal.hide();
                    this.addDevice();
                }
            },

            addDevice() {
                this.$axios({
                    method: 'post',
                    url: '/add-device',
                    data: {
                        'name': this.name,
                    },
                    timeout: 10000
                }).then(response => {
                    this.$root.$emit('showInfo', "Your device was added successfully");
                    this.$root.$emit('bv::show::modal', 'info-modal');
                    this.$root.$emit('added-device', response.data);
                }).catch((err) => {
                    let error = err.response.data;

                    if (error.type && (error.type === "ValidationError" || error.type === "DemoAccountError")) {
                        this.$root.$emit('showError', error.body);
                    } else {
                        this.$root.$emit('showError', 'An error occurred.');
                    }

                    this.$root.$emit('bv::show::modal', 'error-modal');
                });
            },
        }

    }
</script>