<template>
    <div>
        <b-row v-if="selectedDeviceView === 'grid'">
            <virtual-device-item v-for="device in deviceList" :key="device.id" :device='device' @view="viewVirtualDevice" @edit="editVirtualDevice"
                                 @delete="deleteVirtualDevice" @link="linkVirtualDevice" @unlink="unlinkVirtualDevice"/>
        </b-row>

        <virtual-device-table-view v-else :device-list="deviceList" @view="viewVirtualDevice" @edit="editVirtualDevice" @delete="deleteVirtualDevice"
                                   @link="linkVirtualDevice" @unlink="unlinkVirtualDevice"/>

        <b-col v-if="deviceList.length === 0" class="px-0"  sm="6" lg="3">
            <b-card no-body class="bg-primary modules-container">
                <b-card-body class="pb-0">
                    <h4 class="mb-0">{{ $t('devices.noDeviceTitle') }}</h4>
                    <p class="my-3">{{ $t('devices.noDeviceDesc') }}</p>
                </b-card-body>
            </b-card>
        </b-col>

        <AddDeviceModal/>

        <LinkingOptionsModal/>

        <LinkByPasscodeModal/>

        <UnlinkDeviceModal/>

        <DeleteDeviceModal/>
    </div>
</template>

<script>
    import {computed, onMounted, onUnmounted} from "@vue/composition-api";
    import VirtualDeviceItem from "./VirtualDeviceItem";
    import VirtualDeviceTableView from "./VirtualDeviceTableView";
    import AddDeviceModal from "@/components/virtual-device/AddDeviceModal";
    import LinkingOptionsModal from "@/components/virtual-device/LinkingOptionsModal";
    import LinkByPasscodeModal from "@/components/virtual-device/LinkByPasscodeModal";
    import UnlinkDeviceModal from "@/components/virtual-device/UnlinkDeviceModal";
    import DeleteDeviceModal from "@/components/virtual-device/DeleteDeviceModal";
    import {isMobileNavigationMode} from "@/utilities/mobile.navigation.utility";

    export default {
        name: "VirtualDeviceList",

        components: {
            VirtualDeviceItem,
            VirtualDeviceTableView,
            AddDeviceModal,
            LinkingOptionsModal,
            LinkByPasscodeModal,
            UnlinkDeviceModal,
            DeleteDeviceModal
        },

        setup(props, {root}) {
            const selectedDeviceView = computed(() => root.$store.getters['user/devicesView']);
            const deviceList = computed(() => {
                let list = root.$store.getters['virtualDevice/deviceList'];
                let deviceArr = [];

                for (let key in list) {
                    deviceArr.push(list[key]);
                }

                return deviceArr;
            });
            const showingBottomNav = computed(() => isMobileNavigationMode(root.$platform));

            onMounted(() => {
                root.$on('added-device', addedDevice);
                root.$on('deleted-device', deletedDevice);
                root.$on('linked-device', linkedDevice);
                root.$on('unlinked-device', unlinkedDevice);
                root.$on('send-link-request', sendLinkRequest);
            });

            onUnmounted(() => {
                root.$off('added-device');
                root.$off('deleted-device');
                root.$off('linked-device');
                root.$off('unlinked-device');
                root.$off('send-link-request');
            });

            function viewVirtualDevice(deviceId) {
                root.$router.push({name: 'device', params: {id: deviceId}}, () => {
                    //on complete
                }, () => {
                    //on abort
                    root.$Progress.fail();
                    root.$emit('showInfo', root.$t('devices.noDataForDisplay'));
                    root.$emit('bv::show::modal', 'info-modal');
                });
            }

            function editVirtualDevice(deviceId) {
                root.$router.push({name: 'edit-device', params: {id: deviceId}}, () => {
                    //on complete
                }, () => {
                    //on abort
                    root.$Progress.fail();
                    root.$emit('showInfo', root.$t('devices.linkToEdit'));
                    root.$emit('bv::show::modal', 'info-modal');
                });
            }

            function deleteVirtualDevice(deviceId) {
                root.$emit('device-to-delete-id', deviceId);
                root.$emit('bv::show::modal', 'deleteDeviceModal');
            }

            function linkVirtualDevice(deviceId) {
                root.$emit('device-to-link-id', deviceId);
                if (root.$platform === 'web') {
                    root.$emit('bv::show::modal', 'LinkByPasscodeModal');
                } else {
                    root.$emit('bv::show::modal', 'linkingOptionsModal');
                }
            }

            function unlinkVirtualDevice(deviceId) {
                root.$emit('device-to-unlink-id', deviceId);
                root.$emit('bv::show::modal', 'unlinkDeviceModal');
            }

            function showAddDeviceModal() {
                root.$emit('bv::show::modal', 'addDeviceModal');
            }

            function addedDevice(device) {
                root.$store.commit('virtualDevice/addDevice', device);
            }

            function deletedDevice(id) {
                root.$store.commit('virtualDevice/deleteDevice', id);
            }

            function linkedDevice(id, mac_address) {
                root.$store.commit('virtualDevice/linkDevice', {id: id, macAddress: mac_address});
            }

            function unlinkedDevice(id) {
                root.$store.commit('virtualDevice/unlinkDevice', id);
            }

            function sendLinkRequest(deviceId, passcode) {
                root.$axios({
                    method: 'put',
                    url: '/link-device',
                    params: {
                        'id': deviceId,
                        'passcode': passcode
                    },
                    timeout: 10000
                }).then(response => {
                    root.$emit('showInfo', root.$t('devices.linkSuccess'));
                    root.$emit('bv::show::modal', 'info-modal');
                    root.$emit('linked-device', response.data.id, response.data.mac_address);
                }).catch((err) => {
                    if (err.response) {
                        let error = err.response.data;
                        if (error.type) {
                            if (error.type === "ValidationError") {
                                root.$emit('showError', root.$format(error.body.passcode));
                            } else if (error.type === "DemoAccountError" || error.type === 'LinkingError') {
                                root.$emit('showError', error.body);
                            }
                        } else {
                            root.$emit('showError', root.$t('devices.linkFailed'));
                        }
                    } else {
                        root.$emit('showError', root.$t('devices.timeout'));
                    }
                    root.$emit('bv::show::modal', 'error-modal');
                });
            }

            return {
                deviceList,
                viewVirtualDevice,
                editVirtualDevice,
                deleteVirtualDevice,
                linkVirtualDevice,
                unlinkVirtualDevice,
                showingBottomNav,
                showAddDeviceModal,
                selectedDeviceView
            }
        }
    }
</script>

<style scoped>
    .plus-icon {
        font-size: 4.5rem;
    }

    .plus-icon:hover {
        cursor: pointer;
    }
</style>
